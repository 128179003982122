<template>
  <div class="contact">
    <nav-tab
        :list="[{title: lang ==='en'?'Company Profile':'公司介绍', link: '/about/profile'}, {title: lang === 'en'?'News':'新闻动态', link: '/about/news'}, {
        title: lang === 'en'?'Exhibition':'展会信息',
        link: '/about/information'
      }, {title: lang === 'en'?'Contact':'联系我们', link: '/about/contact'}]"
        :active="lang === 'en'?'Contact':'联系我们'"></nav-tab>
    <post-list type="6"/>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import StaticPage from "@/components/static-page/static-page";
import {GetStaticPageDetailApi} from "@/api/static_page";
import NavTab from "@/components/nav-tab/nav-tab";
import PostList from "@/components/post-list/post-list";
import {getLang} from "@/utils/lang";

export default {
  name: "contact",
  components: {PostList, NavTab, StaticPage, SafeArea},
  data() {
    return {
      lang: getLang(),
      html: '',
      header: require("@/assets/about/about.jpg"),
      load: false,
    }
  },
  created() {

    this.getDetail()
  },
  methods: {
    getDetail() {
      // GetStaticPageDetailApi(2).then(res => {
      //   this.load = true
      //   this.html = res.content
      // })
    }
  }
}
</script>

<style scoped>

</style>
