<template>
  <div class="static-page">
    <safe-area>
      <div class="page-box">
        <img :src="header" class="header-image"/>
        <div class="header-title">
          {{ title }}
        </div>
        <div class="page-content" v-html="handle(html)">

        </div>
      </div>

    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";

export default {
  name: "static-page",
  components: {SafeArea},
  props: {
    header: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: ''
    }
  },
  methods: {
    handle(html) {
      return html.replace(/\<img/gi, '<img style="max-width:100%;height:auto" ')
    }
  }
}
</script>

<style scoped>
.static-page {
  background: #f3f4f5;
}

.page-box {
  position: relative;
  padding: 50px;
}

.page-content {
  background: #ffffff;
  min-height: 500px;
  z-index: 22;
  position: relative;
  margin: 50px 20px;
}

.header-title {
  z-index: 22;
  position: relative;
  margin: 10px 20px;
  text-align: center;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
}

.header-image {
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.page-content {
  padding: 30px;
}
</style>
